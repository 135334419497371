export const COLORS = {
  'bg-primary': {
    light: '#fcfcfc',
    dark: '#050505',
  },
  'bg-primary-rgb': {
    light: '254,254,254',
    dark: '5,5,5',
  },
  'bg-secondary': {
    light: '#ffffff',
    dark: '#151617',
  },
  'bg-inset': {
    light: '#eff0f5',
    dark: '#151617',
  },
  'border-primary': {
    light: 'rgba(65,67,78,0.16)',
    dark: 'rgba(255,255,255,0.12)',
  },
  'text-primary': {
    light: '#050505',
    dark: '#fbfbfc',
  },
  'text-secondary': {
    light: '#2f3037',
    dark: '#DEDFE5',
  },
  'text-tertiary': {
    light: '#525560',
    dark: '#a9abb6',
  },
  'text-quaternary': {
    light: '#686B78',
    dark: '#9094A6',
  },
  'text-placeholder': {
    light: 'rgba(82,85,96,0.5)',
    dark: 'rgba(145,148,161,0.5)',
  },
  'text-on-primary': {
    light: '#fbfbfc',
    dark: '#050505',
  },
  'text-link': {
    light: '#007AFF',
    dark: '#FF9500',
  },
  'text-link-rgb': {
    light: '0,122,255',
    dark: '255,149,0',
  },
  'accent-blue': {
    light: '#007AFF',
    dark: '#0A84FF',
  },
  'accent-green': {
    light: '#34C759',
    dark: '#32D74B',
  },
  'accent-orange': {
    light: '#FF9500',
    dark: '#FF9F0A',
  },
  'accent-red': {
    light: '#FF3B30',
    dark: '#FF453A',
  },
  'accent-yellow': {
    light: '#FFCC00',
    dark: '#FFD60A',
  },
  'accent-purple': {
    light: '#AF52DE',
    dark: '#BF5AF2',
  },
  'accent-pink': {
    light: '#FF2D55',
    dark: '#FF375F',
  },
  'social-facebook': {
    light: '#1877f2',
    dark: '#1877f2',
  },
  'social-twitter': {
    light: '#00ACED',
    dark: '#00ACED',
  },
}
